<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('document_request_title')" :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('document_request_title')" :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12">
                    <ValidationObserver ref="documentForm">
                        <div class="mb-2">
                            <h6 class="mb-0">{{ $t('student_information') | toUpperCase }}</h6>
                        </div>
                        <div class="border px-5 pt-5 pb-2 mb-4 rounded">
                            <div class="row">
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('student_number')">
                                        {{ formData.student_number }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('name_surname')">
                                        {{ formData.name + ' ' + formData.surname }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('national_number')">
                                        {{ formData.national_id }}
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('faculty')">
                                        {{ formData.faculty_name }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('program')">
                                        {{ formData.program_name }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('class')">
                                        {{ formData.class }}
                                    </b-form-group>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('email')">
                                        {{ formData.email }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('mobile_number')">
                                        {{ formData.mobile_tel }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-sm-6 col-xl-3">
                                    <b-form-group :label="$t('address')">
                                        {{ formData.address }}
                                    </b-form-group>
                                </div>
                                <div class="col-12 col-lg-6">
                                    <b-form-group :label="' '">
                                        <b-button variant="outline-primary" @click="openSmsVerify">{{
                                            $t('update_info_btn') }}</b-button>
                                    </b-form-group>
                                </div>
                            </div>
                            <!-- <div class="row mt-4">
                                <div class="col-12 col-xl-12 border px-5 pt-5 pb-2 rounded">
                                    <b-form-group :label="$t('information')">
                                        {{ $t('document_request_info_text') }}
                                    </b-form-group>
                                </div>
                            </div> -->
                            <div class="row mt-4">
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <ValidationProvider name="documentType" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('document_type')">
                                            <document-request-type-selectbox @returnOptions="returnOptions"
                                                :validate-error="errors[0]" v-model="documentForm.documentType">
                                            </document-request-type-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <ValidationProvider name="count" rules="required|max_value:2"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('count')">
                                            <b-form-input :disabled="countDisabled" v-mask="'##'" type="number"
                                                v-model="documentForm.count"
                                                :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                            <b-form-invalid-feedback v-if="errors[0]"
                                                v-html="errors[0]"></b-form-invalid-feedback>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <ValidationProvider name="typeOfDelivery" rules="required" v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('type_of_delivery')">
                                            <parameter-selectbox code="document_request_delivery_types"
                                                :withoutItems="documentForm.documentType == 'transcript_in_sealed_envelope' ? 'online' : ''"
                                                :validate-error="errors[0]" v-model="documentForm.typeOfDelivery"
                                                :disabled="deliveryDisabled">
                                            </parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4"
                                    v-show="documentForm.typeOfDelivery == 'address'">
                                    <ValidationProvider name="address"
                                        :rules="documentForm.typeOfDelivery == 'address' ? 'required' : ''"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('address')">
                                            <b-form-textarea v-model="documentForm.address"
                                                :class="errors[0] ? 'is-invalid' : ''" rows="3" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <div class="col-12 col-sm-6 col-lg-4">
                                    <ValidationProvider name="explanation" rules="required|max:240"
                                        v-slot="{ valid, errors }">
                                        <b-form-group :label="$t('explanation')">
                                            <b-form-textarea v-model="documentForm.explanation"
                                                :class="errors[0] ? 'is-invalid' : ''" rows="5" />
                                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                        </b-form-group>
                                    </ValidationProvider>
                                </div>
                                <!-- <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="signatureType" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('signature_type')">
                                            <parameter-selectbox
                                                code="document_request_signature_types"
                                                :validate-error="errors[0]"
                                                v-model="documentForm.signatureType"
                                                :disabled="documentForm.documentType == 'transcript_in_sealed_envelope' ? true : false">
                                            </parameter-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div> -->


                                <!-- <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <ValidationProvider name="language" rules="required" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('language')">
                                            <language-selectbox
                                                :validate-error="errors[0]"
                                                v-model="documentForm.language">
                                            </language-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div> -->

                                <!-- <div class="col-12 col-sm-4 col-md-4 col-lg-4" v-show="documentForm.typeOfDelivery != 'address'">
                                    <ValidationProvider name="campusId" :rules="documentForm.typeOfDelivery == 'campus' ? 'required' : ''" v-slot="{valid, errors}">
                                        <b-form-group :label="$t('campus')">
                                            <campus-selectbox
                                                :validate-error="errors[0]"
                                                v-model="documentForm.campusId"
                                                :disabled="documentForm.typeOfDelivery != 'campus' ? true : false">
                                            </campus-selectbox>
                                        </b-form-group>
                                    </ValidationProvider>
                                </div> -->

                                <!-- <div class="col-12 col-sm-4 col-md-4 col-lg-4">
                                    <b-card border-variant="info" :header="$t('price')" align="center">
                                        <b-card-text>{{calcPrice}}</b-card-text>
                                    </b-card>
                                </div> -->

                                <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 mt-4">
                                    <ValidationProvider name="accept" rules="required" v-slot="{valid, errors}">
                                        <b-form-group>
                                            <div @click="openModalClick">
                                                <b-form-checkbox v-model="documentForm.accept"
                                                                 :disabled="!documentForm.accept">
                                                    <span v-html="$t('statement_2')" @click="openModalClick"></span>
                                                </b-form-checkbox>
                                            </div>
                                        </b-form-group>
                                        <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]"></b-form-invalid-feedback>
                                    </ValidationProvider>
                                </div> -->
                            </div>
                        </div>
                    </ValidationObserver>
                </div>
                <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                    <b-form-group>
                        <b-button variant="primary" @click="sendForm">{{ $t('save') }}</b-button>
                    </b-form-group>
                </div>
            </div>
        </app-layout>
        <CommonModal ref="statementModal" size="lg">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('clarification_text') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div class="row">
                    <div class="col-12 mb-4" style="white-space: pre-line;"
                        v-if="$i18n.locale && $i18n.locale == 'TR'">
                        {{ $t('leave_with_consent_statement2_content') }}
                    </div>
                    <div class="auto-as-parent" v-else>
                        <img class="auto-as-parent" :src="engClarificationText_1" alt="..." />
                        <img class="auto-as-parent" :src="engClarificationText_2" alt="..." />
                        <img class="auto-as-parent" :src="engClarificationText_3" alt="..." />
                    </div>
                </div>
                <b-button variant="primary" type="button" @click="checkStatement2">{{ $t('read_and_accept') }}</b-button>
            </template>
        </CommonModal>
        <CommonModal ref="gsmVerifyModal">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('mobile_number_update') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="mobileForm">
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-12 col-lg-12">
                                <ValidationProvider name="gsmNo" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group :label="$t('mobile_number')">
                                        <b-form-input v-model="gsmVerifyForm.gsmNo" v-mask="'(+49)-##-########'"
                                            :state="errors[0] ? false : (valid ? true : null)"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between"
                            :class="[{ 'mb-4': forgotPass }, { 'mb-0': forgotPass }]">
                            <b-button type="submit" variant="primary" @click="infoUpdate()" v-show="smsSendButtonStatus">{{
                                $t('send') }}</b-button>
                            <b-button type="submit" variant="outline-primary" @click="AddressUpdateClick"
                                v-show="smsSendButtonStatus">{{ $t('address_update') }}</b-button>
                        </div>
                    </ValidationObserver>
                    <div class="visibility-hidden overflow-hidden h-0 fade"
                        :class="[{ 'visibility-visible, h-auto': forgotPass }, { 'show': forgotPass }]">
                        <b-alert :variant="alertVariant" :show="alertShow"><span v-if="alertVariant == 'success'">{{
                            $t('verify_code_again_not') }}</span> <span v-if="alertVariant == 'danger'">{{
        $t('send_again_not') }}</span></b-alert>
                        <ValidationObserver ref="smsVerifyActionForm">
                            <div class="mb-3">
                                <ValidationProvider name="smsVerifyCode" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <div class="d-flex justify-content-between align-items-center">
                                            <label class="col-form-label">{{ $t('verification_code') }}</label>
                                            <div class="text-muted"><i class="ri-time-line top-plus-2 mr-1"></i>{{
                                                timerCount }} sn</div>
                                        </div>
                                        <b-form-input :state="errors[0] ? false : (valid ? true : null)"
                                            v-model="smsVerifyActionForm.verifyCode"></b-form-input>
                                        <b-form-invalid-feedback v-if="errors[0]"
                                            v-html="errors[0]"></b-form-invalid-feedback>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="d-flex justify-content-between">
                                <b-button type="submit" variant="primary" @click="gsmVerify">{{ $t('verify') }}</b-button>
                                <b-button type="submit" variant="outline-secondary" @click="EnterCodeAgainFunc()"
                                    v-show="smsSendAgainButtonStatus">{{ $t('send_again_btn') }}
                                </b-button>
                            </div>
                        </ValidationObserver>
                    </div>
                </div>
            </template>
        </CommonModal>
        <CommonModal ref="addressModal">
            <template v-slot:CommonModalTitle>
                <div>{{ $t('address_update') }}</div>
            </template>
            <template v-slot:CommonModalContent>
                <div>
                    <ValidationObserver ref="addressForm">
                        <div class="row">
                            <div class="col-12 col-md-12">
                                <ValidationProvider name="city" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('city') }}</label>
                                        <city-selectbox :validateError="errors[0]"
                                            v-model="addressForm.city"></city-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-12">
                                <ValidationProvider name="district" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('district') }}</label>
                                        <district-outline-selectbox :validateError="errors[0]" :city_id="addressForm.city"
                                            v-model="addressForm.district"></district-outline-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                            <div class="col-12 col-md-12">
                                <ValidationProvider name="address" rules="required" v-slot="{ valid, errors }">
                                    <b-form-group>
                                        <label>{{ $t('address') }}</label>
                                        <b-form-textarea no-resize v-model="addressForm.address"
                                            :state="errors[0] ? false : (valid ? true : null)" rows="4"
                                            class="h-40 h-auto-sm" maxlength="100"></b-form-textarea>
                                        <div class="invalid-feedback d-block" v-if="errors[0]" v-html="errors[0]"></div>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>
                        </div>
                        <div class="d-flex justify-content-between">
                            <b-button type="submit" variant="primary" @click="addressUpdate">{{ $t('update') }}</b-button>
                        </div>
                    </ValidationObserver>
                </div>
            </template>
        </CommonModal>
    </div>
</template>

<script>
// Components
import AppLayout from '@/layouts/AppLayout';
import Header from '@/layouts/AppLayout/Header';
import HeaderMobile from '@/layouts/AppLayout/HeaderMobile';
import ParameterSelectbox from '@/components/interactive-fields/ParameterSelectbox';
import DocumentRequestTypeSelectbox from '@/components/interactive-fields/DocumentRequestTypeSelectbox';
import CommonModal from '@/components/elements/CommonModal';
import CitySelectbox from '@/components/interactive-fields/CitySelectbox';
import DistrictOutlineSelectbox from '@/components/interactive-fields/DistrictOutlineSelectbox';

// Services
import DocumentRequestService from '@/services/DocumentRequestService';
import PersonService from '@/services/PersonService';

// Other
import { ValidationObserver, ValidationProvider } from 'vee-validate'

// File
import engClarificationText1 from "@/assets/img/eng_1.jpg"
import engClarificationText2 from "@/assets/img/eng_2.jpg"
import engClarificationText3 from "@/assets/img/eng_3.jpg"


export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,
        ParameterSelectbox,
        DocumentRequestTypeSelectbox,
        CommonModal,
        CitySelectbox,
        DistrictOutlineSelectbox,

        ValidationProvider,
        ValidationObserver,
    },
    data() {
        return {
            formData: {},
            documentForm: {
                documentType: null,
                count: 1,
                typeOfDelivery: null,
                explanation: null,
                address: null
            },
            price: null,
            typeOptions: [],
            deliveryDisabled: false,
            countDisabled: false,

            gsmVerifyForm: {
                gsmNo: null
            },

            smsSendButtonStatus: true,
            smsSendAgainButtonStatus: false,
            alertVariant: "success",
            alertShow: true,
            forgotPass: false,
            timerCount: 5,

            smsVerifyActionForm: {
                verifyCode: null
            },

            engClarificationText_1: engClarificationText1,
            engClarificationText_2: engClarificationText2,
            engClarificationText_3: engClarificationText3,

            addressForm: {
                city: null,
                district: null,
                address: null
            }
        }
    },
    methods: {
        getData() {
            DocumentRequestService.studentForm()
                .then(response => {
                    this.formData = response.data.data
                    this.gsmVerifyForm.gsmNo = response.data.data.mobile_tel
                })
        },

        async sendForm() {
            const isValid = await this.$refs.documentForm.validate();
            if (isValid) {
                let formData = {
                    document_type: this.documentForm.documentType,
                    count: this.documentForm.count,
                    type_of_delivery: this.documentForm.typeOfDelivery,
                    explanation: this.documentForm.explanation,
                    address: this.documentForm.typeOfDelivery == 'address' ? this.documentForm.address : null,
                }

                DocumentRequestService.store(formData).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message));
                    this.$router.push('/my-requests/4')
                }).catch(e => {
                    if (e.status == '406') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$toast.error(this.$t('api.' + e.data.message));
                        }
                    }
                    else {
                        if (e.data.errors.document_type) {
                            this.$refs.documentForm.errors.documentType.push(e.data.errors.document_type[0]);
                        }
                        if (e.data.errors.count) {
                            this.$refs.documentForm.errors.count.push(e.data.errors.count[0]);
                        }
                        if (e.data.errors.type_of_delivery) {
                            this.$refs.documentForm.errors.typeOfDelivery.push(e.data.errors.type_of_delivery[0]);
                        }
                        if (e.data.errors.explanation) {
                            this.$refs.documentForm.errors.explanation.push(e.data.errors.explanation[0]);
                        }
                        if (e.data.errors.address) {
                            this.$refs.documentForm.errors.address.push(e.data.errors.address[0]);
                        }
                    }
                })
            }
        },

        returnOptions(options) {
            this.typeOptions = options;
        },

        openModalClick() {
            this.$refs.statementModal.$refs.commonModal.show()
        },

        checkStatement2() {
            this.$refs.statementModal.$refs.commonModal.hide()
        },

        openSmsVerify() {
            this.$refs.gsmVerifyModal.$refs.commonModal.show()
        },

        async infoUpdate() {
            const isValid = await this.$refs.mobileForm.validate();
            if (isValid) {
                let gsmData = {
                    mobile_tel: this.gsmVerifyForm.gsmNo
                }

                PersonService.sendSmsCode(gsmData).then(response => {
                    let data = response.data.data;
                    this.forgotPass = true
                    this.timerCount = data.expires_in
                    this.timerEnabled = true
                    this.smsSendButtonStatus = false
                    this.smsSendAgainButtonStatus = false
                }).catch(e => {
                    if (e.status == '404') { ///406 geldiği zaman değiştirilecek
                        if (e.data.message) {
                            this.$refs.mobileForm.errors.gsmNo.push(e.data.message);
                        }
                    }
                    else {
                        if (e.data.errors.phone) {
                            this.$refs.mobileForm.errors.gsmNo.push(e.data.errors.phone[0]);
                        }
                        if (e.data.errors.token) {
                            this.$refs.mobileForm.errors.gsmNo.push(e.data.errors.token[0]);
                        }
                    }
                })
            }

        },

        AddressUpdateClick() {
            this.$refs.addressModal.$refs.commonModal.show()
            this.$refs.gsmVerifyModal.$refs.commonModal.hide()
        },

        async gsmVerify() {

            const isValid = await this.$refs.smsVerifyActionForm.validate();
            if (isValid) {
                let smsData = {
                    sms_code: this.smsVerifyActionForm.verifyCode
                }
                PersonService.verifySmsCode(smsData)
                    .then(response => {
                        let data = response.data;
                        if (data.success == false) {
                            this.$refs.smsVerifyActionForm.errors.smsVerifyCode.push(data.message);
                        }
                        else {
                            this.$refs.gsmVerifyModal.$refs.commonModal.hide()
                            this.$toast.success(this.$t('api.' + data.message));
                            this.$refs.emailVerifyModal.$refs.commonModal.show()
                            this.getData()
                        }
                    })
            }

        },

        EnterCodeAgainFunc() {
            this.alertVariant = "success"
            this.infoUpdate()
        },

        async addressUpdate() {
            const isValid = await this.$refs.addressForm.validate();
            if (isValid) {
                let addressData = {
                    contact_address: this.addressForm.address,
                    contact_city_id: this.addressForm.city,
                    contact_district_id: this.addressForm.district
                }

                PersonService.patchAddress(addressData)
                    .then(response => {
                        let data = response.data
                        this.$toast.success(this.$t('api.' + data.message));
                        this.$refs.addressModal.$refs.commonModal.hide()
                        this.getData()
                    })
                    .catch(e => {
                        if (e.status == '404') { ///406 geldiği zaman değiştirilecek
                            if (e.data.message) {
                                this.$refs.addressForm.errors.city.push(e.data.message);
                            }
                        }
                        else {
                            if (e.data.errors.contact_address) {
                                this.$refs.addressForm.errors.address.push(e.data.errors.contact_address[0]);
                            }
                            if (e.data.errors.contact_city_id) {
                                this.$refs.addressForm.errors.city.push(e.data.errors.contact_city_id[0]);
                            }
                            if (e.data.errors.contact_district_id) {
                                this.$refs.addressForm.errors.district.push(e.data.errors.contact_district_id[0]);
                            }
                        }
                    })
            }
        }
    },

    watch: {
        timerEnabled() {
            this.timerCount--;
        },
        timerCount: {
            handler(value) {

                if (value > 0 && this.timerEnabled) {
                    setTimeout(() => {
                        this.timerCount--;
                    }, 1000);
                }
                else if (value > 0 && !this.timerEnabled) {
                    this.alertShow = false
                    this.smsSendAgainButtonStatus = false
                }
                else {
                    this.alertVariant = "danger"
                    this.alertShow = true
                    this.smsSendAgainButtonStatus = true
                }

            },
            immediate: true,
        },

        timerEnabledEmail() {
            this.timerCountEmail--;
        },
        timerCountEmail: {
            handler(value) {

                if (value > 0 && this.timerEnabledEmail) {
                    setTimeout(() => {
                        this.timerCountEmail--;
                    }, 1000);
                }
                else if (value > 0 && !this.timerEnabledEmail) {
                    this.alertShowEmail = false
                    this.smsSendAgainButtonStatus = false
                }
                else {
                    this.alertVariantEmail = "danger"
                    this.alertShowEmail = true
                    this.emailSendAgainButtonStatus = true
                }

            },
            immediate: true,
        }
    },

    computed: {
        calcPrice: function () {
            var result = ''
            if (this.documentForm.documentType != null && this.documentForm.documentType != '') {
                this.typeOptions.forEach(item => {
                    if (item.value == this.documentForm.documentType) {
                        let priceObj = item.data.split(':')
                        let price = priceObj[1].replace('}', '')
                        result = price == 0 ? this.$t('priceless') + ' 0TL' : price + ' TL'
                    }
                })
                return result
            } else {
                return '-'
            }
        }
    },

    created() {
        this.getData()
    }
}
</script>

<style>
.auto-as-parent {
    height: 100%;
    width: 100%;
}
</style>

